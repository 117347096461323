import React from "react";
import ReactDOM from "react-dom/client";
//import "./index.css";
import App from "./App";
//import reportWebVitals from "./reportWebVitals";
//TODO:  en manywebsocket acepto cors de localhost
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(React.createElement(React.StrictMode, null,
    React.createElement(App, null)));
//reportWebVitals();
