import React from "react";
const App = () => {
    return (React.createElement("div", { style: {
            maxWidth: "100%",
            overflowX: "hidden",
            position: "absolute",
            height: "2000px",
            width: "100vw",
            background: "radial-gradient(81.24% 228.99% at 6.28% 9.18%, #42CDED 0%, #0F87A4 100%)",
        } },
        React.createElement("div", { style: {
                position: "absolute",
                width: "45vh",
                top: "33vh",
                left: "0",
                right: "0",
                marginLeft: "auto",
                marginRight: "auto",
            } },
            React.createElement("svg", { viewBox: "0 0 248 220", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { d: "M84.2695 9.90845C84.2695 11.0865 84.7065 12.0745 85.5805 12.8725C86.4545 13.6705 87.4995 14.0695 88.7155 14.0695C89.9315 14.0695 90.9575 13.6705 91.7935 12.8725C92.6675 12.0745 93.1045 11.0865 93.1045 9.90845C93.1045 8.69245 92.6675 7.70445 91.7935 6.94445C90.9575 6.14645 89.9315 5.74745 88.7155 5.74745C87.4995 5.74745 86.4545 6.14645 85.5805 6.94445C84.7065 7.70445 84.2695 8.69245 84.2695 9.90845ZM85.0675 20.0545V46.2745H92.2495V20.0545H85.0675Z", fill: "#002652" }),
                React.createElement("path", { d: "M98.8321 48.0985C98.9841 50.3785 99.5921 52.3924 100.656 54.1404C101.758 55.9264 103.335 57.2945 105.387 58.2445C107.439 59.2325 109.985 59.7265 113.025 59.7265C115.533 59.7265 117.851 59.2325 119.979 58.2445C122.107 57.2945 123.798 55.7745 125.052 53.6845C126.344 51.5945 126.99 48.9345 126.99 45.7045V20.0545H119.865V45.7045C119.865 47.4905 119.523 48.9725 118.839 50.1505C118.193 51.3285 117.319 52.2025 116.217 52.7725C115.153 53.3425 114.013 53.6274 112.797 53.6274C111.467 53.6274 110.308 53.3995 109.32 52.9435C108.332 52.4875 107.553 51.8415 106.983 51.0055C106.413 50.2075 106.109 49.2385 106.071 48.0985H98.8321ZM98.3191 32.9365C98.3191 35.8245 98.9081 38.2755 100.086 40.2895C101.264 42.2655 102.822 43.7665 104.76 44.7925C106.698 45.8185 108.75 46.3315 110.916 46.3315C113.12 46.3315 115.058 45.8185 116.73 44.7925C118.44 43.7285 119.77 42.1895 120.72 40.1755C121.708 38.1615 122.202 35.7485 122.202 32.9365C122.202 30.0865 121.708 27.6735 120.72 25.6975C119.77 23.6835 118.44 22.1445 116.73 21.0805C115.058 20.0165 113.12 19.4845 110.916 19.4845C108.75 19.4845 106.698 19.9975 104.76 21.0235C102.822 22.0495 101.264 23.5695 100.086 25.5835C98.9081 27.5595 98.3191 30.0105 98.3191 32.9365ZM105.957 32.9365C105.957 31.3405 106.28 30.0105 106.926 28.9465C107.572 27.8445 108.446 27.0085 109.548 26.4385C110.65 25.8685 111.828 25.5835 113.082 25.5835C113.918 25.5835 114.735 25.7545 115.533 26.0965C116.331 26.4005 117.053 26.8565 117.699 27.4645C118.383 28.0725 118.915 28.8325 119.295 29.7445C119.675 30.6565 119.865 31.7205 119.865 32.9365C119.865 34.5325 119.523 35.8815 118.839 36.9835C118.193 38.0475 117.338 38.8645 116.274 39.4345C115.248 39.9665 114.184 40.2325 113.082 40.2325C111.828 40.2325 110.65 39.9475 109.548 39.3775C108.446 38.8075 107.572 37.9905 106.926 36.9265C106.28 35.8245 105.957 34.4945 105.957 32.9365Z", fill: "#002652" }),
                React.createElement("path", { d: "M153.932 1.81445V46.2745H161.342V1.81445H153.932ZM132.386 33.1645C132.386 36.0525 132.975 38.5225 134.153 40.5745C135.331 42.6265 136.889 44.1845 138.827 45.2485C140.765 46.3125 142.817 46.8445 144.983 46.8445C147.187 46.8445 149.125 46.2935 150.797 45.1915C152.507 44.0895 153.837 42.5125 154.787 40.4605C155.775 38.4085 156.269 35.9765 156.269 33.1645C156.269 30.3145 155.775 27.8825 154.787 25.8685C153.837 23.8165 152.507 22.2395 150.797 21.1375C149.125 20.0355 147.187 19.4845 144.983 19.4845C142.817 19.4845 140.765 20.0165 138.827 21.0805C136.889 22.1445 135.331 23.7025 134.153 25.7545C132.975 27.7685 132.386 30.2385 132.386 33.1645ZM140.024 33.1645C140.024 31.6065 140.347 30.2575 140.993 29.1175C141.639 27.9775 142.513 27.1035 143.615 26.4955C144.717 25.8875 145.895 25.5835 147.149 25.5835C148.251 25.5835 149.315 25.8875 150.341 26.4955C151.405 27.0655 152.26 27.9205 152.906 29.0605C153.59 30.2005 153.932 31.5685 153.932 33.1645C153.932 34.7605 153.59 36.1285 152.906 37.2685C152.26 38.4085 151.405 39.2825 150.341 39.8905C149.315 40.4605 148.251 40.7455 147.149 40.7455C145.895 40.7455 144.717 40.4415 143.615 39.8335C142.513 39.2255 141.639 38.3515 140.993 37.2115C140.347 36.0715 140.024 34.7225 140.024 33.1645Z", fill: "#002652" }),
                React.createElement("path", { d: "M180.811 46.8445C183.965 46.8445 186.663 46.2365 188.905 45.0205C191.185 43.7665 193.009 41.9045 194.377 39.4345L187.936 37.4395C187.176 38.6935 186.207 39.6435 185.029 40.2895C183.889 40.8975 182.502 41.2015 180.868 41.2015C179.424 41.2015 178.189 40.8785 177.163 40.2325C176.137 39.5865 175.358 38.6555 174.826 37.4395C174.332 36.2235 174.085 34.7415 174.085 32.9935C174.123 31.1695 174.389 29.6495 174.883 28.4335C175.415 27.1795 176.156 26.2485 177.106 25.6405C178.094 24.9945 179.31 24.6715 180.754 24.6715C181.932 24.6715 182.958 24.9375 183.832 25.4695C184.706 26.0015 185.371 26.7235 185.827 27.6355C186.321 28.5475 186.568 29.6495 186.568 30.9415C186.568 31.2075 186.492 31.5495 186.34 31.9675C186.226 32.3475 186.093 32.6515 185.941 32.8795L187.936 30.2575H170.893V34.8175H194.377C194.415 34.5895 194.434 34.2855 194.434 33.9055C194.472 33.5255 194.491 33.1645 194.491 32.8225C194.491 30.0105 193.959 27.6165 192.895 25.6405C191.831 23.6265 190.273 22.1065 188.221 21.0805C186.207 20.0165 183.756 19.4845 180.868 19.4845C177.98 19.4845 175.472 20.0545 173.344 21.1945C171.216 22.2965 169.563 23.8735 168.385 25.9255C167.245 27.9395 166.675 30.3525 166.675 33.1645C166.675 35.9385 167.245 38.3515 168.385 40.4035C169.563 42.4175 171.216 43.9945 173.344 45.1345C175.472 46.2745 177.961 46.8445 180.811 46.8445Z", fill: "#002652" }),
                React.createElement("path", { d: "M195.462 20.0545L209.826 49.0105L224.19 20.0545H216.267L209.826 35.8435L203.385 20.0545H195.462Z", fill: "#002652" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M123.851 166.833L115.857 159.771L116.519 159.021L124.513 166.083L123.851 166.833Z", fill: "#002A5A" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M123.851 166.832L131.193 159.49L131.901 160.197L124.558 167.54L123.851 166.832Z", fill: "#002A5A" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M123.851 184.175L115.857 177.113L116.519 176.364L124.513 183.425L123.851 184.175Z", fill: "#002A5A" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M123.851 184.175L131.193 176.832L131.901 177.54L124.558 184.882L123.851 184.175Z", fill: "#002A5A" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M123.851 201.517L115.857 194.456L116.519 193.706L124.513 200.768L123.851 201.517Z", fill: "#002A5A" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M123.851 201.517L131.193 194.175L131.901 194.882L124.558 202.224L123.851 201.517Z", fill: "#002A5A" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M123.851 218.859L115.857 211.798L116.519 211.048L124.513 218.11L123.851 218.859Z", fill: "#002A5A" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M123.851 218.859L131.193 211.517L131.901 212.224L124.558 219.566L123.851 218.859Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M13.3367 132.348C12.8667 132.348 12.4467 132.303 12.0767 132.213C11.7067 132.123 11.3717 132.003 11.0717 131.853C10.7717 131.693 10.5017 131.503 10.2617 131.283C10.0317 131.063 9.82171 130.823 9.63171 130.563L10.2917 130.023C10.6817 130.523 11.1117 130.908 11.5817 131.178C12.0517 131.438 12.6467 131.568 13.3667 131.568C14.2067 131.568 14.8517 131.383 15.3017 131.013C15.7517 130.633 15.9767 130.088 15.9767 129.378C15.9767 128.728 15.7867 128.248 15.4067 127.938C15.0367 127.628 14.5067 127.418 13.8167 127.308L12.6467 127.113C12.1267 127.023 11.6917 126.888 11.3417 126.708C11.0017 126.528 10.7267 126.318 10.5167 126.078C10.3067 125.828 10.1567 125.553 10.0667 125.253C9.98671 124.953 9.94671 124.643 9.94671 124.323C9.94671 123.383 10.2517 122.683 10.8617 122.223C11.4817 121.753 12.3117 121.518 13.3517 121.518C14.2017 121.518 14.8967 121.658 15.4367 121.938C15.9867 122.208 16.4267 122.583 16.7567 123.063L16.1267 123.618C15.8267 123.198 15.4567 122.873 15.0167 122.643C14.5867 122.413 14.0217 122.298 13.3217 122.298C12.5417 122.298 11.9317 122.468 11.4917 122.808C11.0617 123.138 10.8467 123.633 10.8467 124.293C10.8467 124.903 11.0267 125.368 11.3867 125.688C11.7467 125.998 12.2817 126.208 12.9917 126.318L14.1467 126.513C14.6767 126.603 15.1167 126.743 15.4667 126.933C15.8167 127.113 16.0967 127.328 16.3067 127.578C16.5167 127.828 16.6617 128.103 16.7417 128.403C16.8317 128.703 16.8767 129.018 16.8767 129.348C16.8767 130.308 16.5667 131.048 15.9467 131.568C15.3267 132.088 14.4567 132.348 13.3367 132.348Z", fill: "#002652" }),
                React.createElement("path", { d: "M20.7859 132.168V121.698H27.1009V122.478H21.6859V126.468H26.9059V127.248H21.6859V131.388H27.1009V132.168H20.7859Z", fill: "#002652" }),
                React.createElement("path", { d: "M31.28 132.168V121.698H37.595V122.478H32.18V126.468H37.4V127.248H32.18V131.388H37.595V132.168H31.28Z", fill: "#002652" }),
                React.createElement("path", { d: "M55.3133 132.348C54.8433 132.348 54.4233 132.303 54.0533 132.213C53.6833 132.123 53.3483 132.003 53.0483 131.853C52.7483 131.693 52.4783 131.503 52.2383 131.283C52.0083 131.063 51.7983 130.823 51.6083 130.563L52.2683 130.023C52.6583 130.523 53.0883 130.908 53.5583 131.178C54.0283 131.438 54.6233 131.568 55.3433 131.568C56.1833 131.568 56.8283 131.383 57.2783 131.013C57.7283 130.633 57.9533 130.088 57.9533 129.378C57.9533 128.728 57.7633 128.248 57.3833 127.938C57.0133 127.628 56.4833 127.418 55.7933 127.308L54.6233 127.113C54.1033 127.023 53.6683 126.888 53.3183 126.708C52.9783 126.528 52.7033 126.318 52.4933 126.078C52.2833 125.828 52.1333 125.553 52.0433 125.253C51.9633 124.953 51.9233 124.643 51.9233 124.323C51.9233 123.383 52.2283 122.683 52.8383 122.223C53.4583 121.753 54.2883 121.518 55.3283 121.518C56.1783 121.518 56.8733 121.658 57.4133 121.938C57.9633 122.208 58.4033 122.583 58.7333 123.063L58.1033 123.618C57.8033 123.198 57.4333 122.873 56.9933 122.643C56.5633 122.413 55.9983 122.298 55.2983 122.298C54.5183 122.298 53.9083 122.468 53.4683 122.808C53.0383 123.138 52.8233 123.633 52.8233 124.293C52.8233 124.903 53.0033 125.368 53.3633 125.688C53.7233 125.998 54.2583 126.208 54.9683 126.318L56.1233 126.513C56.6533 126.603 57.0933 126.743 57.4433 126.933C57.7933 127.113 58.0733 127.328 58.2833 127.578C58.4933 127.828 58.6383 128.103 58.7183 128.403C58.8083 128.703 58.8533 129.018 58.8533 129.348C58.8533 130.308 58.5433 131.048 57.9233 131.568C57.3033 132.088 56.4333 132.348 55.3133 132.348Z", fill: "#002652" }),
                React.createElement("path", { d: "M65.8074 132.348C64.5974 132.348 63.6974 131.898 63.1074 130.998C62.5274 130.088 62.2374 128.733 62.2374 126.933C62.2374 125.133 62.5274 123.783 63.1074 122.883C63.6974 121.973 64.5974 121.518 65.8074 121.518C67.0174 121.518 67.9124 121.973 68.4924 122.883C69.0824 123.783 69.3774 125.133 69.3774 126.933C69.3774 128.733 69.0824 130.088 68.4924 130.998C67.9124 131.898 67.0174 132.348 65.8074 132.348ZM65.8074 131.568C66.7074 131.568 67.3674 131.228 67.7874 130.548C68.2074 129.858 68.4174 128.888 68.4174 127.638V126.228C68.4174 124.988 68.2074 124.023 67.7874 123.333C67.3674 122.643 66.7074 122.298 65.8074 122.298C64.9074 122.298 64.2474 122.643 63.8274 123.333C63.4074 124.023 63.1974 124.988 63.1974 126.228V127.638C63.1974 128.888 63.4074 129.858 63.8274 130.548C64.2474 131.228 64.9074 131.568 65.8074 131.568Z", fill: "#002652" }),
                React.createElement("path", { d: "M78.8516 123.048H78.7616L76.3016 128.793L73.8416 123.048H73.7516V132.168H72.9266V121.698H74.0966L76.2866 126.873H76.3616L78.5366 121.698H79.6766V132.168H78.8516V123.048Z", fill: "#002652" }),
                React.createElement("path", { d: "M83.7507 132.168V121.698H90.0657V122.478H84.6507V126.468H89.8707V127.248H84.6507V131.388H90.0657V132.168H83.7507Z", fill: "#002652" }),
                React.createElement("path", { d: "M107.784 132.348C107.314 132.348 106.894 132.303 106.524 132.213C106.154 132.123 105.819 132.003 105.519 131.853C105.219 131.693 104.949 131.503 104.709 131.283C104.479 131.063 104.269 130.823 104.079 130.563L104.739 130.023C105.129 130.523 105.559 130.908 106.029 131.178C106.499 131.438 107.094 131.568 107.814 131.568C108.654 131.568 109.299 131.383 109.749 131.013C110.199 130.633 110.424 130.088 110.424 129.378C110.424 128.728 110.234 128.248 109.854 127.938C109.484 127.628 108.954 127.418 108.264 127.308L107.094 127.113C106.574 127.023 106.139 126.888 105.789 126.708C105.449 126.528 105.174 126.318 104.964 126.078C104.754 125.828 104.604 125.553 104.514 125.253C104.434 124.953 104.394 124.643 104.394 124.323C104.394 123.383 104.699 122.683 105.309 122.223C105.929 121.753 106.759 121.518 107.799 121.518C108.649 121.518 109.344 121.658 109.884 121.938C110.434 122.208 110.874 122.583 111.204 123.063L110.574 123.618C110.274 123.198 109.904 122.873 109.464 122.643C109.034 122.413 108.469 122.298 107.769 122.298C106.989 122.298 106.379 122.468 105.939 122.808C105.509 123.138 105.294 123.633 105.294 124.293C105.294 124.903 105.474 125.368 105.834 125.688C106.194 125.998 106.729 126.208 107.439 126.318L108.594 126.513C109.124 126.603 109.564 126.743 109.914 126.933C110.264 127.113 110.544 127.328 110.754 127.578C110.964 127.828 111.109 128.103 111.189 128.403C111.279 128.703 111.324 129.018 111.324 129.348C111.324 130.308 111.014 131.048 110.394 131.568C109.774 132.088 108.904 132.348 107.784 132.348Z", fill: "#002652" }),
                React.createElement("path", { d: "M121.248 132.168L120.258 128.973H116.298L115.293 132.168H114.363L117.693 121.698H118.878L122.193 132.168H121.248ZM118.323 122.658H118.218L116.508 128.193H120.033L118.323 122.658Z", fill: "#002652" }),
                React.createElement("path", { d: "M131.322 123.048H131.232L128.772 128.793L126.312 123.048H126.222V132.168H125.397V121.698H126.567L128.757 126.873H128.832L131.007 121.698H132.147V132.168H131.322V123.048Z", fill: "#002652" }),
                React.createElement("path", { d: "M136.221 132.168V121.698H139.971C140.901 121.698 141.606 121.963 142.086 122.493C142.566 123.013 142.806 123.728 142.806 124.638C142.806 125.548 142.566 126.268 142.086 126.798C141.606 127.318 140.901 127.578 139.971 127.578H137.121V132.168H136.221ZM137.121 126.798H139.956C140.586 126.798 141.061 126.628 141.381 126.288C141.701 125.948 141.861 125.518 141.861 124.998V124.278C141.861 123.758 141.701 123.328 141.381 122.988C141.061 122.648 140.586 122.478 139.956 122.478H137.121V126.798Z", fill: "#002652" }),
                React.createElement("path", { d: "M147.211 132.168V121.698H148.111V131.388H153.241V132.168H147.211Z", fill: "#002652" }),
                React.createElement("path", { d: "M157.21 132.168V121.698H163.525V122.478H158.11V126.468H163.33V127.248H158.11V131.388H163.525V132.168H157.21Z", fill: "#002652" }),
                React.createElement("path", { d: "M170.749 132.348C170.279 132.348 169.859 132.303 169.489 132.213C169.119 132.123 168.784 132.003 168.484 131.853C168.184 131.693 167.914 131.503 167.674 131.283C167.444 131.063 167.234 130.823 167.044 130.563L167.704 130.023C168.094 130.523 168.524 130.908 168.994 131.178C169.464 131.438 170.059 131.568 170.779 131.568C171.619 131.568 172.264 131.383 172.714 131.013C173.164 130.633 173.389 130.088 173.389 129.378C173.389 128.728 173.199 128.248 172.819 127.938C172.449 127.628 171.919 127.418 171.229 127.308L170.059 127.113C169.539 127.023 169.104 126.888 168.754 126.708C168.414 126.528 168.139 126.318 167.929 126.078C167.719 125.828 167.569 125.553 167.479 125.253C167.399 124.953 167.359 124.643 167.359 124.323C167.359 123.383 167.664 122.683 168.274 122.223C168.894 121.753 169.724 121.518 170.764 121.518C171.614 121.518 172.309 121.658 172.849 121.938C173.399 122.208 173.839 122.583 174.169 123.063L173.539 123.618C173.239 123.198 172.869 122.873 172.429 122.643C171.999 122.413 171.434 122.298 170.734 122.298C169.954 122.298 169.344 122.468 168.904 122.808C168.474 123.138 168.259 123.633 168.259 124.293C168.259 124.903 168.439 125.368 168.799 125.688C169.159 125.998 169.694 126.208 170.404 126.318L171.559 126.513C172.089 126.603 172.529 126.743 172.879 126.933C173.229 127.113 173.509 127.328 173.719 127.578C173.929 127.828 174.074 128.103 174.154 128.403C174.244 128.703 174.289 129.018 174.289 129.348C174.289 130.308 173.979 131.048 173.359 131.568C172.739 132.088 171.869 132.348 170.749 132.348Z", fill: "#002652" }),
                React.createElement("path", { d: "M188.617 121.698H192.082C192.932 121.698 193.597 121.933 194.077 122.403C194.557 122.873 194.797 123.533 194.797 124.383C194.797 125.033 194.657 125.548 194.377 125.928C194.097 126.298 193.717 126.558 193.237 126.708V126.738C193.847 126.868 194.327 127.143 194.677 127.563C195.037 127.973 195.217 128.538 195.217 129.258C195.217 129.678 195.152 130.068 195.022 130.428C194.892 130.778 194.707 131.083 194.467 131.343C194.227 131.603 193.937 131.808 193.597 131.958C193.267 132.098 192.897 132.168 192.487 132.168H188.617V121.698ZM192.262 131.388C192.922 131.388 193.422 131.223 193.762 130.893C194.102 130.563 194.272 130.128 194.272 129.588V128.973C194.272 128.433 194.102 127.998 193.762 127.668C193.422 127.338 192.922 127.173 192.262 127.173H189.517V131.388H192.262ZM192.007 126.423C192.607 126.423 193.062 126.273 193.372 125.973C193.692 125.673 193.852 125.268 193.852 124.758V124.158C193.852 123.648 193.692 123.243 193.372 122.943C193.062 122.633 192.607 122.478 192.007 122.478H189.517V126.423H192.007Z", fill: "#002652" }),
                React.createElement("path", { d: "M199.186 132.168V121.698H205.501V122.478H200.086V126.468H205.306V127.248H200.086V131.388H205.501V132.168H199.186Z", fill: "#002652" }),
                React.createElement("path", { d: "M210.175 132.168V121.698H211.075V131.388H216.205V132.168H210.175Z", fill: "#002652" }),
                React.createElement("path", { d: "M223.22 132.348C222.01 132.348 221.11 131.898 220.52 130.998C219.94 130.088 219.65 128.733 219.65 126.933C219.65 125.133 219.94 123.783 220.52 122.883C221.11 121.973 222.01 121.518 223.22 121.518C224.43 121.518 225.325 121.973 225.905 122.883C226.495 123.783 226.79 125.133 226.79 126.933C226.79 128.733 226.495 130.088 225.905 130.998C225.325 131.898 224.43 132.348 223.22 132.348ZM223.22 131.568C224.12 131.568 224.78 131.228 225.2 130.548C225.62 129.858 225.83 128.888 225.83 127.638V126.228C225.83 124.988 225.62 124.023 225.2 123.333C224.78 122.643 224.12 122.298 223.22 122.298C222.32 122.298 221.66 122.643 221.24 123.333C220.82 124.023 220.61 124.988 220.61 126.228V127.638C220.61 128.888 220.82 129.858 221.24 130.548C221.66 131.228 222.32 131.568 223.22 131.568Z", fill: "#002652" }),
                React.createElement("path", { d: "M230.774 132.168L229.874 121.698H230.684L231.464 131.238H231.569L233.219 124.608H234.239L235.889 131.238H235.979L236.774 121.698H237.554L236.654 132.168H235.319L233.759 125.703H233.669L232.109 132.168H230.774Z", fill: "#002652" }),
                React.createElement("path", { d: "M2.22514 77.1841L0.641144 78.2821C0.917144 78.8581 1.28914 79.3921 1.75714 79.8841C2.22514 80.3761 2.77114 80.7721 3.39514 81.0721C4.03114 81.3601 4.72114 81.5041 5.46514 81.5041C6.00514 81.5041 6.52114 81.4141 7.01314 81.2341C7.51714 81.0661 7.96714 80.8261 8.36314 80.5141C8.75914 80.1901 9.07114 79.7941 9.29914 79.3261C9.52714 78.8581 9.64114 78.3301 9.64114 77.7421C9.64114 77.1901 9.54514 76.7101 9.35314 76.3021C9.17314 75.8941 8.92714 75.5401 8.61514 75.2401C8.30314 74.9281 7.95514 74.6701 7.57115 74.4661C7.19914 74.2621 6.82714 74.0941 6.45514 73.9621C5.78314 73.7221 5.23714 73.4881 4.81714 73.2601C4.39714 73.0321 4.08514 72.7921 3.88114 72.5401C3.68914 72.2761 3.59314 71.9821 3.59314 71.6581C3.59314 71.2981 3.73114 70.9861 4.00714 70.7221C4.28314 70.4461 4.71514 70.3081 5.30314 70.3081C5.72314 70.3081 6.08914 70.3921 6.40114 70.5601C6.72514 70.7161 7.00714 70.9321 7.24714 71.2081C7.48714 71.4721 7.68514 71.7601 7.84114 72.0721L9.56914 71.1001C9.35314 70.6561 9.05314 70.2301 8.66914 69.8221C8.29714 69.4141 7.83514 69.0781 7.28314 68.8141C6.73114 68.5501 6.08314 68.4181 5.33914 68.4181C4.58314 68.4181 3.90514 68.5621 3.30514 68.8501C2.71714 69.1261 2.24914 69.5221 1.90114 70.0381C1.55314 70.5421 1.37914 71.1301 1.37914 71.8021C1.37914 72.3901 1.49314 72.8941 1.72114 73.3141C1.94914 73.7221 2.23714 74.0701 2.58514 74.3581C2.93314 74.6341 3.29914 74.8681 3.68314 75.0601C4.06714 75.2401 4.41514 75.3841 4.72714 75.4921C5.25514 75.6841 5.71714 75.8821 6.11314 76.0861C6.52114 76.2781 6.83914 76.5181 7.06714 76.8061C7.29514 77.0821 7.40914 77.4541 7.40914 77.9221C7.40914 78.4141 7.22914 78.8161 6.86914 79.1281C6.50914 79.4401 6.04114 79.5961 5.46514 79.5961C4.98514 79.5961 4.55314 79.5001 4.16914 79.3081C3.78514 79.1041 3.43114 78.8221 3.10714 78.4621C2.79514 78.0901 2.50114 77.6641 2.22514 77.1841Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M14.521 74.9341C14.521 74.0701 14.701 73.3021 15.061 72.6301C15.433 71.9461 15.943 71.4121 16.591 71.0281C17.239 70.6441 17.977 70.4521 18.805 70.4521C19.657 70.4521 20.401 70.6441 21.037 71.0281C21.685 71.4121 22.189 71.9461 22.549 72.6301C22.909 73.3021 23.089 74.0701 23.089 74.9341C23.089 75.7981 22.903 76.5721 22.531 77.2561C22.171 77.9281 21.667 78.4561 21.019 78.8401C20.383 79.2241 19.645 79.4161 18.805 79.4161C17.977 79.4161 17.239 79.2241 16.591 78.8401C15.943 78.4561 15.433 77.9281 15.061 77.2561C14.701 76.5721 14.521 75.7981 14.521 74.9341ZM12.271 74.9341C12.271 75.8701 12.433 76.7401 12.757 77.5441C13.081 78.3481 13.531 79.0441 14.107 79.6321C14.695 80.2201 15.391 80.6821 16.195 81.0181C16.999 81.3421 17.869 81.5041 18.805 81.5041C19.753 81.5041 20.623 81.3421 21.415 81.0181C22.219 80.6821 22.915 80.2201 23.503 79.6321C24.091 79.0441 24.547 78.3481 24.871 77.5441C25.195 76.7401 25.357 75.8701 25.357 74.9341C25.357 73.9861 25.195 73.1161 24.871 72.3241C24.547 71.5321 24.085 70.8481 23.485 70.2721C22.897 69.6841 22.207 69.2281 21.415 68.9041C20.623 68.5801 19.753 68.4181 18.805 68.4181C17.881 68.4181 17.017 68.5801 16.213 68.9041C15.421 69.2281 14.725 69.6841 14.125 70.2721C13.537 70.8481 13.081 71.5321 12.757 72.3241C12.433 73.1161 12.271 73.9861 12.271 74.9341Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M29.9066 70.5421H35.5406V68.6341H29.9066V70.5421ZM29.9066 75.5461H35.3606V73.6921H29.9066V75.5461ZM28.5926 68.6341V81.2341H30.6806V68.6341H28.5926Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M37.3444 70.5961H40.6564V81.2341H42.7984V70.5961H46.1284V68.6341H37.3444V70.5961Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M60.7836 77.0761L57.1656 68.0041L53.5836 77.0761L50.5956 68.6341H48.1296L53.2776 81.9001L57.1656 72.9901L61.0536 81.9001L66.2016 68.6341H63.7356L60.7836 77.0761Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M68.6302 77.9941H75.2902L74.8762 76.1941H69.0262L68.6302 77.9941ZM71.9242 72.1981L73.9222 76.8781L73.9762 77.4001L75.6862 81.2341H78.0802L71.9242 67.9321L65.7682 81.2341H68.1622L69.9082 77.2921L69.9442 76.8241L71.9242 72.1981Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M83.308 75.2221L87.322 81.2341H89.878L85.612 75.2221H83.308ZM80.716 68.6341V81.2341H82.84V68.6341H80.716ZM81.976 70.4701H84.514C84.994 70.4701 85.414 70.5541 85.774 70.7221C86.146 70.8901 86.434 71.1301 86.638 71.4421C86.842 71.7541 86.944 72.1381 86.944 72.5941C86.944 73.0381 86.842 73.4221 86.638 73.7461C86.434 74.0581 86.146 74.2981 85.774 74.4661C85.414 74.6341 84.994 74.7181 84.514 74.7181H81.976V76.4641H84.604C85.516 76.4641 86.308 76.3021 86.98 75.9781C87.664 75.6541 88.192 75.1981 88.564 74.6101C88.936 74.0221 89.122 73.3321 89.122 72.5401C89.122 71.7481 88.936 71.0641 88.564 70.4881C88.192 69.9001 87.664 69.4441 86.98 69.1201C86.308 68.7961 85.516 68.6341 84.604 68.6341H81.976V70.4701Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M93.9051 81.2341H100.259V79.3621H93.9051V81.2341ZM93.9051 70.5061H100.259V68.6341H93.9051V70.5061ZM93.9051 75.4021H99.899V73.5661H93.9051V75.4021ZM92.591 68.6341V81.2341H94.679V68.6341H92.591Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M110.611 68.6341V81.2341H112.771V68.6341H110.611ZM114.679 81.2341C115.963 81.2341 117.091 80.9761 118.063 80.4601C119.035 79.9321 119.791 79.2001 120.331 78.2641C120.883 77.3161 121.159 76.2061 121.159 74.9341C121.159 73.6621 120.883 72.5581 120.331 71.6221C119.791 70.6741 119.035 69.9421 118.063 69.4261C117.091 68.8981 115.963 68.6341 114.679 68.6341H111.925V70.6321H114.625C115.249 70.6321 115.825 70.7221 116.353 70.9021C116.881 71.0821 117.337 71.3521 117.721 71.7121C118.117 72.0721 118.423 72.5221 118.639 73.0621C118.867 73.6021 118.981 74.2261 118.981 74.9341C118.981 75.6421 118.867 76.2661 118.639 76.8061C118.423 77.3461 118.117 77.7961 117.721 78.1561C117.337 78.5161 116.881 78.7861 116.353 78.9661C115.825 79.1461 115.249 79.2361 114.625 79.2361H111.925V81.2341H114.679Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M125.716 81.2341H132.07V79.3621H125.716V81.2341ZM125.716 70.5061H132.07V68.6341H125.716V70.5061ZM125.716 75.4021H131.71V73.5661H125.716V75.4021ZM124.402 68.6341V81.2341H126.49V68.6341H124.402Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M140.661 77.4901L136.953 68.6341H134.505L140.661 81.9361L146.817 68.6341H144.369L140.661 77.4901Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M150.767 81.2341H157.121V79.3621H150.767V81.2341ZM150.767 70.5061H157.121V68.6341H150.767V70.5061ZM150.767 75.4021H156.761V73.5661H150.767V75.4021ZM149.453 68.6341V81.2341H151.541V68.6341H149.453Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M160.906 68.6341V81.2341H168.034V79.3261H163.03V68.6341H160.906Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M171.727 74.9341C171.727 74.0701 171.907 73.3021 172.267 72.6301C172.639 71.9461 173.149 71.4121 173.797 71.0281C174.445 70.6441 175.183 70.4521 176.011 70.4521C176.863 70.4521 177.607 70.6441 178.243 71.0281C178.891 71.4121 179.395 71.9461 179.755 72.6301C180.115 73.3021 180.295 74.0701 180.295 74.9341C180.295 75.7981 180.109 76.5721 179.737 77.2561C179.377 77.9281 178.873 78.4561 178.225 78.8401C177.589 79.2241 176.851 79.4161 176.011 79.4161C175.183 79.4161 174.445 79.2241 173.797 78.8401C173.149 78.4561 172.639 77.9281 172.267 77.2561C171.907 76.5721 171.727 75.7981 171.727 74.9341ZM169.477 74.9341C169.477 75.8701 169.639 76.7401 169.963 77.5441C170.287 78.3481 170.737 79.0441 171.313 79.6321C171.901 80.2201 172.597 80.6821 173.401 81.0181C174.205 81.3421 175.075 81.5041 176.011 81.5041C176.959 81.5041 177.829 81.3421 178.621 81.0181C179.425 80.6821 180.121 80.2201 180.709 79.6321C181.297 79.0441 181.753 78.3481 182.077 77.5441C182.401 76.7401 182.563 75.8701 182.563 74.9341C182.563 73.9861 182.401 73.1161 182.077 72.3241C181.753 71.5321 181.291 70.8481 180.691 70.2721C180.103 69.6841 179.413 69.2281 178.621 68.9041C177.829 68.5801 176.959 68.4181 176.011 68.4181C175.087 68.4181 174.223 68.5801 173.419 68.9041C172.627 69.2281 171.931 69.6841 171.331 70.2721C170.743 70.8481 170.287 71.5321 169.963 72.3241C169.639 73.1161 169.477 73.9861 169.477 74.9341Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M185.799 68.6341V81.2341H187.923V68.6341H185.799ZM187.059 70.5061H189.597C190.329 70.5061 190.917 70.6801 191.361 71.0281C191.805 71.3641 192.027 71.8681 192.027 72.5401C192.027 73.2121 191.805 73.7221 191.361 74.0701C190.917 74.4181 190.329 74.5921 189.597 74.5921H187.059V76.4641H189.597C190.509 76.4641 191.301 76.3081 191.973 75.9961C192.657 75.6721 193.185 75.2161 193.557 74.6281C193.929 74.0401 194.115 73.3441 194.115 72.5401C194.115 71.7361 193.929 71.0461 193.557 70.4701C193.185 69.8821 192.657 69.4321 191.973 69.1201C191.301 68.7961 190.509 68.6341 189.597 68.6341H187.059V70.5061Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M199.715 73.5661L203.549 80.0641L207.383 73.5661L208.265 81.2341H210.461L208.733 68.0041L203.549 76.5541L198.383 68.0041L196.655 81.2341H198.851L199.715 73.5661Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M215.011 81.2341H221.365V79.3621H215.011V81.2341ZM215.011 70.5061H221.365V68.6341H215.011V70.5061ZM215.011 75.4021H221.005V73.5661H215.011V75.4021ZM213.697 68.6341V81.2341H215.785V68.6341H213.697Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M234.349 68.6341V77.0941L225.151 68.0041V81.2341H227.239V72.7741L236.437 81.8641V68.6341H234.349Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M239.141 70.5961H242.453V81.2341H244.595V70.5961H247.925V68.6341H239.141V70.5961Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M40.9957 11.9546V14.2271C40.928 14.2271 40.8264 14.2228 40.691 14.2144C40.5556 14.2059 40.4582 14.2017 40.399 14.2017C39.4765 14.2017 38.8502 14.3794 38.5201 14.7349C38.1985 15.0819 38.0377 15.814 38.0377 16.9312V19.9907C38.0377 21.3026 37.8515 22.2378 37.4791 22.7964C37.1067 23.3465 36.4338 23.7401 35.4605 23.9771C36.4254 24.2056 37.094 24.5991 37.4664 25.1577C37.8472 25.7078 38.0377 26.6388 38.0377 27.9507V30.9976C38.0377 32.1147 38.2027 32.8511 38.5328 33.2065C38.8713 33.5705 39.5019 33.7524 40.4244 33.7524C40.4836 33.7524 40.5767 33.7482 40.7037 33.7397C40.8306 33.7313 40.928 33.7271 40.9957 33.7271V35.9614H40.1959C39.3326 35.9614 38.6301 35.9106 38.0885 35.8091C37.5553 35.7075 37.1067 35.5509 36.7428 35.3394C36.2603 35.0516 35.9218 34.6453 35.7271 34.1206C35.5325 33.5959 35.4351 32.7368 35.4351 31.5435V28.395C35.4351 27.1763 35.2109 26.3172 34.7623 25.8179C34.3222 25.3185 33.5689 25.0688 32.5025 25.0688C32.4433 25.0688 32.3502 25.0731 32.2232 25.0815C32.1047 25.09 32.0159 25.0942 31.9566 25.0942V22.8218C32.0159 22.8218 32.1047 22.8302 32.2232 22.8472C32.3502 22.8556 32.4433 22.8599 32.5025 22.8599C33.5689 22.8599 34.3222 22.6102 34.7623 22.1108C35.2109 21.6115 35.4351 20.7567 35.4351 19.5464V16.4106C35.4351 15.1834 35.5325 14.3117 35.7271 13.7954C35.9218 13.2707 36.2603 12.8687 36.7428 12.5894C37.1151 12.3608 37.5764 12.2 38.1265 12.1069C38.6767 12.0054 39.4426 11.9546 40.4244 11.9546H40.9957Z", fill: "#002A5A" }),
                React.createElement("path", { d: "M50.9753 11.9546H51.5466C52.5199 11.9546 53.2774 12.0054 53.819 12.1069C54.3692 12.2 54.8304 12.3608 55.2028 12.5894C55.6853 12.8687 56.0238 13.2707 56.2185 13.7954C56.4131 14.3201 56.5105 15.1919 56.5105 16.4106V19.5464C56.5105 20.7651 56.7305 21.6242 57.1706 22.1235C57.6107 22.6144 58.3682 22.8599 59.4431 22.8599C59.4939 22.8599 59.5827 22.8556 59.7097 22.8472C59.8366 22.8302 59.9297 22.8218 59.989 22.8218V25.0942C59.9297 25.0942 59.8366 25.09 59.7097 25.0815C59.5912 25.0731 59.5023 25.0688 59.4431 25.0688C58.3767 25.0688 57.6192 25.3185 57.1706 25.8179C56.7305 26.3172 56.5105 27.1763 56.5105 28.395V31.5435C56.5105 32.7537 56.4131 33.617 56.2185 34.1333C56.0238 34.6496 55.6853 35.0516 55.2028 35.3394C54.8389 35.5594 54.3819 35.716 53.8317 35.8091C53.2816 35.9106 52.5199 35.9614 51.5466 35.9614H50.9753V33.7271C51.043 33.7271 51.1403 33.7313 51.2673 33.7397C51.3942 33.7482 51.4873 33.7524 51.5466 33.7524C52.4691 33.7524 53.0912 33.5747 53.4128 33.2192C53.7429 32.8638 53.9079 32.1232 53.9079 30.9976V27.9507C53.9079 26.6558 54.0983 25.729 54.4792 25.1704C54.8601 24.6118 55.5371 24.214 56.5105 23.9771C55.5371 23.7401 54.8601 23.3465 54.4792 22.7964C54.0983 22.2378 53.9079 21.3026 53.9079 19.9907V16.9312C53.9079 15.814 53.7471 15.0819 53.4255 14.7349C53.1039 14.3794 52.486 14.2017 51.572 14.2017C51.5127 14.2017 51.4154 14.2059 51.28 14.2144C51.1446 14.2228 51.043 14.2271 50.9753 14.2271V11.9546Z", fill: "#002A5A" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M26.29 7.67821C37.1653 7.80449 44.8815 4.6263 47.6203 2.79693L45.954 0.302246C43.6691 1.8284 35.9821 5.08773 24.8474 4.64008L23.2872 4.57736L23.2872 6.13888C23.2872 19.0058 23.5622 39.5547 46.0473 52.3044L47.527 49.6948C27.4512 38.3111 26.3459 20.444 26.29 7.67821Z", fill: "#002652" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M65.74 7.67821C54.8647 7.80449 47.1484 4.6263 44.4096 2.79693L46.0759 0.302246C48.3608 1.8284 56.0478 5.08773 67.1825 4.64008L68.7428 4.57736L68.7428 6.13888C68.7427 19.0058 68.4677 39.5547 45.9827 52.3044L44.5029 49.6948C64.5788 38.3111 65.6841 20.444 65.74 7.67821Z", fill: "#002652" }),
                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M45.0128 51L45.0128 2.83447L47.0128 2.83447L47.0128 51L45.0128 51Z", fill: "#002652" })))));
};
export default App;
